window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js');
// require('./bootstrap');
import { Tooltip, Toast, Popover } from 'bootstrap';
window.Tooltip = Tooltip;
window.Toast = Toast;
window.Popover = Popover;
// window.Password = require('bootstrap-show-password.js');
// import Password from 'bootstrap-show-password';
window.Password = require('bootstrap-show-password');
